.editDelete-dropDown{
position: absolute;
}

.hidden{
    display: none;
}

.icon {
    position: absolute;
    top: 6px;
    right: 6px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
}
