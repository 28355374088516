.signup-page-container{
    background: url('../../resources/images/SeaOfClouds.svg');
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eceff1;
    padding: 20px;
}

.signup-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eceff1;
    order: 1;
    background-color: white;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 7px;
    animation: slideUp 1s ease-out forwards;
}

.signup-container-new {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-left: 20px; 
}

.signup-title {
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem; 
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%; 
    text-align: center;
    animation: fadeIn 1s ease-out forwards;
}

.signup-form-container h1 {
    color: #333;
    margin-bottom: 2rem;
}

.signup-form-container form {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.signup-form-container ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
}

.signup-form-container li {
    color: #ff0000;
    margin-bottom: 0.5rem;
}

.signup-form-container label {
    display: block;
    color: #333;
    margin-bottom: 0.5rem;
}

.signup-form-container input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.signup-form-container button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.signup-form-container button:hover {
    background-color: #0056b3;
}


/*! First name and last name Form rows styling*/

.form-row {
    display: flex;
    justify-content: space-between; 
}

.form-row .form-group {
    flex: 1;
    margin: 0 8px; 
}

.form-row .form-group:first-child {
    margin-left: 0;
}

.form-row .form-group:last-child {
    margin-right: 0;
}

/*! Sign Up Image */

.cozy-img img {
    max-width: 580px; 
    max-height: 580px; 
    object-fit: contain; 
    border-radius: 30%;
    margin-left: 100px;
    margin-top: 70px;
    animation: pop 1s ease forwards;
}

.cozy-img {
    order: 2;
}

/* ! Login Redirect */

.login-redirect {
    margin-top: 1rem; 
    text-align: center;
}

.login-redirect a {
    color: #007BFF; 
    text-decoration: none; 
    font-weight: bold; 
}

.login-redirect a:hover {
    text-decoration: underline;
}

/* ! With the Image */

.signup-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eceff1; 
    padding: 20px; 
}

/*! Keyframes */

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes pop {
    0% { transform: scale(0.9); opacity: 0; }
    50% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); }
}
