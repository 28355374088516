#modal-content {
  position: absolute;
  top: 50px;
  width: 636px;
  background: linear-gradient(to right, #0a162c, rgb(0 84 113));
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #9fd8ff;
}

.commentModal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.title-input {
  font-size: 25px;
  width: fit-content;
  height: 28px;
  color: #e6e6fa;
  width: 100%;
}

.title-input:hover {
  cursor: pointer;
}

.fa-pen-to-square {
  position: absolute;
  left: 0%;
  top: 23%;
  transform: translateY(-50%);
  color: #e6e6fa;
  font-size: 11px;
}

.cardTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
}

.cardTitle div {
  margin-left: 10px;
}

.title-information {
  position: relative;
  top: 16px;
  width: 100%;
}

.title-information p {
  color: #e6e6fa;
  margin-top: 0;
  font-size: 13px;
}

#activity .row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 545px;
}

.comment-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-container button {
  border: none;
  color: #e6e6fa;
  background-color: rgba(107, 107, 107, 0);
  padding: 5px;
  cursor: pointer;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}

.row-container p {
  color: #e6e6fa;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.editor-container-1,
.editor-container {
  background-color: #535b6b;
  color: lightgrey;
  padding: 6px;
  width: 94%;
  width: 510px;
  height: fit-content;
  border-radius: 5px;
  overflow: auto;
}

.editor-container-1 {
  margin-left: 29px;
}

.button-container {
  margin-left: 51px;
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-around;
}

.save-2,
.button-container button {
  margin-top: 6px;
  padding: 8px 16px;
  border: none;
  background-color: pink;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
}

.button-container .save {
  background-color: lightblue;
  margin-right: 6px;
}

.save-2 {
  background-color: lightblue;
  margin-left: 36px;
  cursor: pointer;
  margin-bottom: 40px;
}

.button-container .cancel {
  color: black;
}

.save-2:hover,
.button-container .save:hover {
  background-color: rgb(105, 167, 187);
}

.button-container .cancel:hover {
  background-color: rgb(213, 159, 168);
}

.comment-block {
  margin-top: 30px;
}

.comment-block,
.comment-container {
  display: flex;
  flex-direction: row;
}

.comment-block i,
.comment-container i {
  margin-right: 10px;
  margin-top: 17px;
}

.comment-block i {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 27px;
}

.comment_insert {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.comment_insert i {
  margin-top: 24px;
}

.toolbar {
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 5px;
}

.toolbar button {
  color: #e6e6fa;
  height: 32px;
  width: 32px;
  border: 1px solid #e6e6fa;
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(112, 128, 144, 0);
  cursor: pointer;
}

.toolbar button i {
  margin: auto;
}

.name_date {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 5px;
}

.name_date h2 {
  color: #e6e6fa;
  font-size: 15px;
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 0;
  margin-left: 6px;
}

.name_date span {
  color: #e6e6fa;
  font-size: 11px;
}

.comment_info p {
  margin-top: 0;
  width: 510px;
  padding: 6px;
  border: 1px solid #db7093;
  background-color: #2c2a31;
  color: #e6e6fa;
  margin-left: 10px;
  margin-bottom: 2px;
  border-radius: 5px;
}

hr {
  border-color: #e6e6fa;
}

.edit-delete-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80px;
  margin-left: 5px;
}

.edit-delete-btn button {
  width: 48px;
  font-size: smaller;
  border: none;
  background-color: rgba(128, 128, 128, 0);
  color: #e6e6fa;
  cursor: pointer;
}

.edit-delete-btn span {
  color: #e6e6fa;
}

.title-input {
  background-color: transparent;
  color: #e6e6fa;
  border: none;
}

.comment-btn-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.comment-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 120px;
  border: 1px solid black;
}

.comment-btn i {
  margin-right: 8px;
}

.add-cover-btn,
.move-card,
.delete-card {
  border-radius: 5px;
  background-color: #3080b7;
  color: #ede1e1;
  text-align: center;
  cursor: pointer;
  border: none;
  margin-right: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.add-cover-btn:hover,
.move-card:hover,
.delete-card:hover {
  background-color: #0d4e6e;
  box-shadow: 0 0 5px #0d4e6e;
  transform: translateY(-1px);
}

.color-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: -121px;
  width: 192px;
  background-color: #282e34;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #9fd8ff96;
}

.color-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  margin: 5px;
}

.color-option:hover {
  /* cursor: pointer; */
  filter: brightness(1.2);
}

.remove-cover {
  margin: 10px auto;
  background-color: #d5d5d5;
  width: 95%;
  border: none;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d45848;
    color: #d5d5d5;
  }
}

.move-options {
  display: flex;
  flex-direction: column;
}

.destination {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.destination p {
  color: #e6e6fa;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
}

.list-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.move-list__container {
  justify-content: center;
  max-width: 600px;
  justify-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.move-lists {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 121px;
  border-radius: 5px;
  background-color: #278453;
  color: #083900;
  margin: 5px 5px;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.move-lists:hover {
  background-color: #216d44;
  box-shadow: 0 0 5px #216d44;
  transform: translateY(-1px);
  cursor: pointer;
}
