* {
  font-family: 'Coiny', system-ui;
}

.create_list_button {
  background: linear-gradient(to top left, #444bd2, #d88c52, #fc5b80);
  border-radius: 9px;
  color: #d9d9d9;
  border: none;
  cursor: pointer;
  margin: 10px 20px 10px 16px;
  min-width: 266px;
  height: fit-content;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.create_list_button::before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: inherit;
  background-color: #101204;
  z-index: -2;
}

.create_list_button::after {
  content: '';
  position: absolute;
  color: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 9px;
  background: linear-gradient(to top left, #444bd2, #d88c52, #fc5b80);
  z-index: -1;
  transition: 0.1s;
  transform: scale(0);
}

.create_list_button:hover::after {
  z-index: -1;
  box-shadow: 15px 0 30px #444bd2, -15px 0 30px #fc5b80;
  transform: scale(1);
}

#list-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.create_list_button2 {
  background-color: #23344d;
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 36px;
  margin: 5px;
}

.create_list_button2:hover {
  background-color: #0e4a6c;
}

/* !default  */
.default_image {
  background-image: url('../../resources/images/default.svg');
}

/* !Image 1 */
.imageOne__image {
  background-image: url('../../resources/images/colorOne.svg');
}

/* !Image 2 */
.imageTwo__image {
  background-image: url('../../resources/images/colorTwo.svg');
}

/* !image 3 */
.imageThree__image {
  background-image: url('../../resources/images/colorThree.svg');
}

/* !image 4 */
.imageFour__image {
  background-image: url('../../resources/images/colorFour.svg');
}

/*! Image 5 */
.imageFive__image {
  background-image: url('../../resources/images/colorFive.svg');
}

/*  !Image 6 */
.imageSix__image {
  background-image: url('../../resources/images/colorSix.svg');
}

.default_list__head,
.imageOne__list__head,
.imageTwo__list__head,
.imageThree__list__head,
.imageFive__list__head,
.imageSix__list__head,
.imageFour__list__head {
  color: #fd5c81;
}

.default_list__name,
.imageOne__list__name,
.imageTwo__list__name,
.imageThree__list__name,
.imageFive__list__name,
.imageSix__list__name,
.imageFour__list__name {
  color: #a03b3b;
  margin: 5px 0 5px 0;
}

.default_lists,
.imageOne__lists,
.imageTwo__lists,
.imageThree__lists,
.imageFive__lists,
.imageSix__lists,
.imageFour__lists {
  padding: 3px;
  background-color: #101204;
}

.lists__container {
  position: relative;
  border-radius: 8px;
  background-attachment: absolute;
  background-size: cover;
  height: 100vh;
}
.component__name {
  margin-left: 5px;
}
.list__head {
  margin: 0 0 0 30px;
  font-size: 33px;
  padding-top: 15px;
}

.lists__group {
  display: flex;
  margin: 13px 9px;
  overflow: auto;
  padding: 20px 0;
  width: 100%;
  margin-top: 0;
  height: 85vh;
}

.list {
  position: relative;
  text-align: center;
  min-width: 272px;
  border-radius: 15px;
  margin: 10px 10px 10px 20px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 76%),
    0px 0px 20px 0px rgb(255 255 255 / 22%);
  background-color: #101204;
}

.preloader {
  height: 50%;
  width: 50%;
}

.list:hover {
  animation: shake 0.08s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.lists__group:hover .list:not(:hover) {
  filter: blur(10%);
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.preloader {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150"><rect fill="%231B191B" width="100%" height="100%"/><path fill="none" stroke="%239B6AF7" stroke-width="20" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="3.1" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>');

  background-attachment: absolute;
  margin: 0 auto;
  background-size: cover;
  height: 80vh;
  width: 65%;
  z-index: 5;
}

.new-list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.new-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.new-list p {
  margin: 0 0 0 5px;
  font-size: 14px;
  /* color: #aaa; */
}

.fa-plus {
  color: #aaa;
}

.submitButton {
  margin-left: 10px;
}

.list_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.list_form_input {
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.submitButton {
  border: none;
  cursor: pointer;
  background-color: #fd5c80;
  color: white;
  height: 27px;
  border-radius: 5px;
  width: 80px;
  font-size: 14px;
  margin: 0 10px 0 0;
}

.submitButton:hover {
  background-color: #c44762;
}

.submitButton2 {
  border: none;
  cursor: pointer;
  background-color: #e16f4e;
  color: white;
  height: 27px;
  border-radius: 5px;
  width: 120px;
  font-size: 14px;
  margin-left: 10px;
}

.deleteContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.deleteContainer h2 {
  margin: 0;
  color: #e6e6fa;
  font-size: 23px;
}
.deleteContainer p {
  margin: 0 0 10px 0;
  color: #e6e6fa;
}

.yes-btn {
  border: none;
  background-color: #529752;
  width: 50px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.yes-btn:hover {
  background-color: #3f743f;
}

.no-btn {
  border: none;
  background-color: #c84242;
  width: 50px;
  border-radius: 5px;
  cursor: pointer;
}

.no-btn:hover {
  background-color: #a63b3b;
}

.new-list-btns {
  display: flex;
  margin-top: 10px;
}

.x {
  border: none;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.x:hover {
  background-color: #c84242;
}
