.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  height: fit-content;
  /* box-shadow: 0 0 3px 1px black;
  border-radius: 5px; */
  margin: 14px auto;
  cursor: pointer;
}

.add-card-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 5px;
  color: #e6e6fa;
}

.add-card-button:hover {
  background-color: #e6e6fa;
  color: #2e2424;
}

.add-card-button .fa-plus {
  margin: 7px;
}

.add-card-button p {
  margin: 0;
  font-size: 15px;
  color: #aaa;
}

.card-form {
  width: 100%;
  border-radius: 15px;
  background-color: #22272b;
  padding: 5px 8px;
  border: 1px solid #6a6a6a;
}

.card-form-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 5px 0;
  margin-top: 8px;
}

.new-card-add-info input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 3px;
  margin: 5px 0 7px 0;
  border: none;
}

.new-card-name,
.new-card-description {
  color: black;
  font-size: 14px;
}
.new-card-name::placeholder,
.new-card-description::placeholder {
  color: #3a3a3a80;
  font-size: 14px;
}

.cancel-containers {
  height: 30px;
  width: 30px;
  border: 1px solid red;
  cursor: pointer;
}

.cancel-containers:hover {
  background-color: #e6e6fa;
}

.new-card-btn {
  background-color: #58b968;
  border: none;
  cursor: pointer;
  color: #2e2424;
  width: 84px;
  height: 32px;
  border-radius: 5px;
}

.new-card-btn:hover {
  background-color: #479453;
  color: #ffffff;
}

.cancel-new-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #e6e6fa00;
  border: none;
  cursor: pointer;
  color: white;
  width: 32px;
  height: 32px;
  padding: 10px;
  font-size: 18px;
  margin-left: 5px;
  border-radius: 8px;
}

.cancel-new-card:hover {
  background-color: #bf3f3f;
  color: #433434;
}

.new__card__modal-button {
  display: none;
}
