.spotify-modal {
	color: #081222;
	background: #081222;
	padding: 20px;
	border-radius: 4px;
	width: 450px;
	height: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 20px;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;

	align-items: center;
}

.spotify-modal button {
	border: none;
	background-color: rgb(255, 79, 79);
}

.spotify-modal button:hover {
	cursor: pointer;
}

.fa-circle-play {
	margin-right: 15px;
}
.fa-circle-pause {
	margin-right: 15px;
}

.fa-gear {
	margin-right: 15px;
}

.audio-input {
	border: transparent solid 3px;
	width: 95%;
  margin-bottom: 10%;
}

.audio-input:focus {
	outline: none !important;
	border-width: 3px;
	border-style: solid;
	border-image: linear-gradient(to top left, #444bd2, #d88c52, #fc5b80) 40;
	box-shadow: 0 0 10px #719ece;
}

.supported-audio-sites {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  
}

.media_controls_link-p {
	font-size: 30px;
	color: whitesmoke;
}

.volume-container {
  display: inline-block;
}

.range-input {
  accent-color: #444bd2;
}


