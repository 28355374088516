.header{
    background-color: #1B191B;
}
.header > h1{
    padding-top:10px;
    color: #BC6180;
    font-weight: bolder;
    font-size: 30px;
    margin: 0 0 0 18px;
}
