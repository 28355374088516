.boards__container {
	margin: 0 auto;
	padding-top: 50px;
	padding-bottom: 32px;
	/* max-width: 1920px; */
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	background-image: url('../../resources/images/default.svg');
	border-radius: 8px;
	background-attachment: absolute;
	background-size: cover;
	height: 100vh; 
	width: 100vw;
	overflow-y: auto;

}

.outer__boards__container {
	background-color: #0a162c;
	height: 100vh;
	width: 100vw;
}

.boards__board {
  min-width: 200px;	
	border-radius: 3px;
	box-sizing: border-box;
	font-weight: bold;
	height: 200px;
	margin: 0 8px 16px;
	max-width: 320px;
	padding: 4px 8px;
	position: relative;
	color: white;
	width: 25%;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	
	
}

.boards__board:hover, .board__options {
	transition: transform 0.2s;
	transform: scale(1.1);
	z-index: 5;
	box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.01);
}



.boards__group {
	display: flex;
	flex-wrap: wrap;
	width: 80%;
	height: 60%;
	
  
}

.boards__sidebar {
	background-color: #6c547b;
	border-radius: 3px;
	margin: 0 32px 32px 0;
	padding: 0 20px 32px 20px;
	max-height: 100px;
	max-width: 180px;
	width: 20%;
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: 220px;
}

.boards__sidebar-content .description {
	color: lightblue;
}

.tabbed-nav__container {
	background-color: #f4f5f7;
}

.tabbed-nav__group {
	border-bottom: 1px solid #dfe1e6;
	display: flex;
	justify-content: center;
}

.tabbed-nav__link {
	background-color: white;
	border: 1px solid #dfe1e6;
	border-bottom: 0;
	border-radius: 2px;
	font-weight: bold;
	line-height: 20px;
	padding: 10px;
	margin: 0 5px;
	color: #172b4d;
}

body {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 14px;
	margin: 0px;
}

@media screen and (min-width: 500px) {
	.tabbed-nav__group * {
		display: flex;
		text-align: center;
	}
}

@media screen and (max-width: 501px) {
	.tabbed-nav__group,
	.tabbed-nav__link {
		display: block;
	}
}

.boards__img {
	height: 200px;
	max-width: 264 px;
	color: white;
	width: 100%;
	display: flex;
	border-radius: 4px;
	border: rgba(19, 12, 62, 0.983) 1px solid;
	/* background-color: transparent; */
}


.boards__img__navlink:hover {
	border-radius: 4px;
	opacity: 0.9;
	box-shadow: #000000 0px 0px 20px 0px;
}

.fa-ellipsis {
	padding: 5px;
  text-align: end;
  justify-content: end;
  justify-self: end;
  align-items: end;
  color: #dfe1e6;
}

.fa-ellipsis:hover {
  background-color: #172b4db3;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.board-options__button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 20px;
}

.boards__img__navlink:not(:hover) .board-options__button {
  display: none;
}

.board__options {
  /* display: none; */
  z-index: 4;
  position: absolute;
  right: -100px;
}



.board__name {
	background-color: #6c547b;
	font-family: 'Silkscreen', sans-serif;
	position: absolute;
	color: rgb(255, 255, 255);
	font-size: 20px;
	padding-left: 1%;
	padding-right: 1%;
	margin-left: 3%;
	margin-bottom: 5%;
	border-radius: 5px;
}

a {
	color: black;
	text-decoration: none;
}

.boards__options {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.update__board-dropdown {
    position: absolute;
    display: flex;
    color: white;
    z-index: 5;
    background-color: #6c547b;
    border-radius: 3px;
    border: solid black 2px;
    padding: 20px 20px 32px 20px;
    max-width: 180px;
    min-width: 180px;
    width: 20%;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 220px;
    text-align: center;
    justify-content: space-around;
}

/* ! Board Options Styling */

.board__options ul {
    background-color: #333;
    border-radius: 4px;
    padding: 0;
    list-style: none;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.board__options li {
    color: white;
    padding: 8px 12px; 
    font-size: 14px;
    cursor: pointer;
    background: none; 
}

.board__options li:hover {
    background-color: #444;
}

.board__options li:not(:last-child) {
    border-bottom: 1px solid #555; 
}

.update__board-button,
.delete__board-button {
    display: block;
    width: 100%; 
    text-align: left;
    border: none;
    color: white; 
    padding: 8px 12px; 
    background: none; 
    cursor: pointer; 
}

.update__board-button:hover,
.delete__board-button:hover {
    background-color: #444; 
}
