header {
  justify-content: space-between;
  background: linear-gradient(to right, #444bd2, #d88c52, #fc5b80);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 48px !important;
  border-bottom: 1px solid rgba(230, 230, 250, 0.443);
}

.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-bar .create {
  margin-left: 20px;
  width: 68px;
  height: 32px;
  border: none;
  border-radius: 5px;
  color: #e6e6fa;
  background-color: #fc5c80;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-bar .create:hover {
  background-color: #c94967;
}

.mello {
  border: none;
  font-weight: bold;
  font-size: 22px;
  color: #e6e6fa;
  background: none;
}

.nav-bar .Submit {
  width: 275px;
  margin: 0 auto;
  background-color: #e16f4e;
  color: #433434;
  margin-bottom: 8px;
  cursor: pointer;
}

.nav-bar .Submit:hover {
  background-color: #fd7d5a;
}

.logo-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-name p {
  font-weight: bold;
  font-size: 20px;
  color: #e6e6fa;
  margin-left: 10px;
}

.right_side_nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* width: 340px; */
}

.right_side_nav i {
  cursor: pointer;
}

.user-icon:hover {
  cursor: pointer;
}

.profile-dropdown {
  position: absolute;
  right: 20px;
  top: 39px;
  width: 232px;
  padding: 10px;
  font-size: 15px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(184, 104, 255);
  box-shadow: 0px 0px 10px #a84d8c;
  background-color: #081222;
  list-style-type: none;
  z-index: 5;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
}

.name-email {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.name-email p {
  margin: 0;
  font-size: 15px;
  color: #e6e6fa;
}

.profile-dropdown li {
  padding: 8px;
  color: #e6e6fa;
  cursor: pointer;
  font-size: 15px;
}

.profile-dropdown li:hover {
  background-color: rgba(185, 104, 255, 0.497);
  background-color: #532545b3;
}

.account-text {
  font-size: 15px;
  color: #e6e6fa7e;
  margin: 5px 0;
}

.hidden {
  display: none;
}

.search-container {
  position: relative;
}

.search-container input {
  width: 200px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #e6e6fa71;
  padding: 0 10px;
  background-color: #431e3800;
  color: #e6e6fa;
  padding-left: 10px;
}

.search-container input:focus {
  outline: none;
  border: 1px solid #e6e6fa;
  background-color: #431e38;
  /* color: #e6e6fa; */
}

#search {
  padding-left: 29px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-166px, -7px);
}
