.card-cover {
  width: 255px;
  height: 36px;
  /* border: 1px solid black; */
  margin: 0 auto 0 auto;
  border-radius: 9px 9px 0 0;
}

.card-cover-modal {
  width: 634px;
  height: 75px;
  margin: -20px -20px 16px -20px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

/* .card_container {
  border: 1px solid red;
} */

.card_container button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 36px;
  width: 96%;
  padding: 8px 12px 4px;
  background-color: #22272b;
  border-radius: 9px;
  border: none;
  color: #aaa;
  margin: 0px auto 10px auto;
  font-size: 14px;
  cursor: pointer;
}

.card_container button:hover {
  color: #add8e6;
  background-color: #33383b;
}


.card_container.cover button {
  border-radius: 0px 0px 9px 9px;
}

/* .card_container button:hover {
  background-color: #e6e6fa;
  box-shadow: 0px 0px 8px 1px#b9809e;
  color: #845c71;
} */

.fa-message {
  margin-right: 5px;
}
